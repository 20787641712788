<template>
  <get-sales-list-template mode="02"></get-sales-list-template>
</template>

<script>
  import GetSalesListTemplate from '../template/GetSalesListTemplate'

  export default {
    name: "get-shipping-list",
    components: {
      GetSalesListTemplate,
    }
  }

</script>

<style>
</style>
